<template>
  <div v-if="physicalPerson" class="d-flex flex-row address-card-custom">
    <div class="w-100 flex-shrink-0 offcanvas-desktop">
      <div class="navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-around" role="tablist">
        <template v-for="(comp, idx) in componentList">
          <div v-if="comp.canShow" :key="idx" class="navi-item mb-2">
            <router-link active-class="active" class="navi-link cursor-pointer py-4" :data-tab="idx" data-toggle="tab"
              role="tab" aria-selected="false" :to="{ name: comp.routeName }">
              <span class="navi-icon">
                <i :class="comp.icon"></i>
              </span>
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <Avatar :avatar-image="physicalPerson.avatar_display" style="-webkit-box-shadow: none; box-shadow: none"
              :avatar-text="`${physicalPerson.name[0]}${physicalPerson.surname[0]}`" frame size="90px" class="mr-3">
            </Avatar>
            <div>
              <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ fullName }}</a>
              <div v-if="physicalPerson.is_temporary_employee" class="text-muted">
                {{ $t("Temporary Worker") }}
              </div>
              <div v-else-if="physicalPerson.is_employee" class="text-muted">
                {{ $t("Employee") }}
              </div>
              <div v-else class="text-muted">
                {{ $t("Standard person") }}
              </div>
              <div v-if="physicalPerson.citizenship_set.length">
                <country-flag class="mt-2" :country-iso="physicalPerson.citizenship_set[0].country"></country-flag>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <button v-if="!physicalPerson.is_employee" :disabled="addEmployeeDisabled"
              class="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4"
              @click="addAsEmployee">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Communication/Group.svg" />
              </span>
              {{ $t("Add as employee") }}
            </button>
            <button v-else :disabled="rmEmployeeDisabled"
              class="btn btn-block btn-sm btn-light-danger font-weight-bolder text-uppercase py-4" @click="rmAsEmployee">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Communication/Group.svg" />
              </span>
              {{ $t("Remove as employee") }}
            </button>
          </div>

          <div class="py-9">
            <div v-if="isSameAuthUser" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Login mail") }}:
              <span class="text-muted" :style="{ minWidth: '0' }">{{ physicalPerson.user_email }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Email") }}:
              <div class="text-muted" :style="{ minWidth: '0' }">{{ mainEmail }}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Phone") }}: <span class="text-muted" :style="{ minWidth: '0' }">{{ mainPhoneNumber }}</span>
            </div>
            <div v-if="clientRelations" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Client on") }}: <span class="text-muted">{{ clientRelations }} {{
                $t("assignments") }}</span>
            </div>
            <div v-if="contractorRelations" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Contractor on") }}: <span class="text-muted">{{ contractorRelations }} {{
                $t("assignments") }}</span>
            </div>
            <div v-if="partnerRelations" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Supplier on") }}: <span class="text-muted">{{ partnerRelations }} {{
                $t("assignments") }}</span>
            </div>
            <div
              v-if="!physicalPerson.is_employee && !physicalPerson.is_temporary_employee && physicalPerson.user_id != getUserID"
              class="d-flex flex-wrap gap-1 mt-4">
              <div v-if="clientRelations == 0">
                <client-button :loading="isSettingAsClient" :active="physicalPerson.is_client_on_secondments"
                  @click="setAsClientFunction(!physicalPerson.is_client_on_secondments)"></client-button>
              </div>
              <div v-if="contractorRelations == 0">
                <contractor-button :loading="isSettingAsContractor" :active="physicalPerson.is_contractor_on_secondments"
                  @click="setAsContractorFunction(!physicalPerson.is_contractor_on_secondments)"></contractor-button>
              </div>
              <div v-if="partnerRelations == 0">
                <supplier-button :loading="isSettingAsPartner" :active="physicalPerson.is_partner_on_secondments"
                  @click="setAsPartnerFunction(!physicalPerson.is_partner_on_secondments)"></supplier-button>
              </div>
            </div>
          </div>

          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <template v-for="(comp, idx) in componentList">
              <div v-if="comp.canShow" :key="idx" class="navi-item mb-2">
                <router-link active-class="active" class="navi-link cursor-pointer py-4" :data-tab="idx" data-toggle="tab"
                  role="tab" aria-selected="false" :to="{ name: comp.routeName }">
                  <span class="navi-icon">
                    <i :class="comp.icon"></i>
                  </span>
                  <span class="navi-text font-size-lg">{{ comp.name }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row-fluid ml-lg-8">
      <router-view :key="$route.fullPath" v-bind="currentDetailComponentProps"
        v-on="currentDetailComponentEvents"></router-view>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import AuthService from "@/core/services/auth.service";
import ClientButton from "@/view/components/buttons/ClientButton.vue";
import ContractorButton from "@/view/components/buttons/ContractorButton.vue";
import SupplierButton from "@/view/components/buttons/SupplierButton.vue";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import { backendErrorSwal } from "@/core/helpers/swal";
import { createEmployee } from "@/core/abstractions/employee";
import { mapGetters, mapState } from "vuex";
import { dangerToast, successToast } from "@/core/helpers";
import { physicalPersonSetAsButtonSwal } from "@/core/helpers/swal";
import icons from "@/core/config/icons";
import EmployeeService from "@/core/services/employee/employee.service";

export default {
  components: {
    CountryFlag,
    ClientButton,
    ContractorButton,
    SupplierButton,
  },

  data() {
    return {
      icons,
      tabIndex: 0,
      physicalPerson: null,
      addEmployeeDisabled: false,
      rmEmployeeDisabled: false,
      isSettingAsContractor: false,
      isSettingAsClient: false,
      isSettingAsPartner: false,
    };
  },

  computed: {
    ...mapGetters("user", ["getCurrentManagedCompanyId", "isApStaff", "getUserID"]),
    ...mapState("user", ["user"]),
    currentDetailComponentProps() {
      return this.currentDetail?.props;
    },

    currentDetailComponentEvents() {
      return this.currentDetail?.events;
    },

    currentDetail() {
      return this.componentList.find((item) => item.routeName == this.$route.name);
    },

    componentList() {
      return [
        {
          routeName: "detail-physical-information",
          name: this.$t("Personal information"),
          icon: "flaticon2-user",
          canShow: true,
          props: {
            physicalPerson: this.physicalPerson,
          },
          events: {
            updated: this.loadPhysicalPerson,
          },
        },
        {
          routeName: "detail-physical-contacts",
          name: this.$t("Contacts"),
          icon: "flaticon2-phone",
          canShow: true,
          props: {
            physicalPerson: this.physicalPerson,
          },
          events: {
            updated: this.loadPhysicalPerson,
          },
        },
        {
          routeName: "detail-physical-address",
          name: this.$t("Address"),
          icon: "fas fa-road",
          canShow: true,
          props: {
            physicalPerson: this.physicalPerson,
          },
          events: {
            updated: this.loadPhysicalPerson,
          },
        },
        {
          routeName: "detail-physical-employment",
          name: this.$t("Employee Details"),
          icon: "fas fa-briefcase",
          canShow: this.physicalPerson.is_employee,
          props: {
            physicalPerson: this.physicalPerson,
          },
          events: {
            updated: this.loadPhysicalPerson,
          },
        },
        {
          routeName: "detail-physical-documents",
          name: this.$t("Documents"),
          icon: "flaticon2-document",
          canShow: true,
          props: {
            physicalPerson: this.physicalPerson,
          },
          events: {
            updated: this.loadPhysicalPerson,
          },
        },
        {
          routeName: "detail-physical-password",
          name: this.$t("Account security"),
          icon: "fas fa-key",
          canShow: this.isSameAuthUser,
          props: {},
          events: {},
        },
        {
          routeName: "detail-physical-orders",
          name: this.$t("My orders"),
          icon: "fas fa-store",
          canShow: this.isSameAuthUser,
          events: {},
        },
        {
          routeName: "detail-physical-settings",
          name: this.$t("Settings"),
          icon: "flaticon2-gear",
          canShow: this.isSameAuthUser,
          props: {
            physicalPerson: this.physicalPerson,
          },
          events: {},
        },
        {
          routeName: "detail-physical-assignments",
          name: this.$t("Assignments"),
          icon: "flaticon-placeholder-3",
          canShow: this.clientRelations || this.contractorRelations || this.partnerRelations,
          props: {
            physicalPerson: this.physicalPerson,
            assignments: this.personAssignments
          },
          events: {},
        },
      ];
    },

    isSameAuthUser() {
      return AuthService.isSameAuthUser(this.$route.params.userID);
    },

    fullName() {
      return this.physicalPerson?.name + " " + this.physicalPerson?.surname;
    },

    mainPhoneNumber() {
      const mainTelContact = this.physicalPerson?.telcontact_set?.find((el) => !!el.main);
      if (!mainTelContact) {
        if (this.physicalPerson?.telcontact_set?.length) {
          return this.physicalPerson.telcontact_set[0].number;
        }
        return "";
      }
      return mainTelContact.number;
    },

    mainEmail() {
      const mainEmail = this.physicalPerson?.extraemail_set?.find((el) => !!el.main);
      if (!mainEmail) {
        if (this.physicalPerson?.extraemail_set?.length) {
          return this.physicalPerson.extraemail_set[0].email;
        }
        return "";
      }
      return mainEmail.email;
    },

    userID() {
      return this.$route.params.userID;
    },
    clientRelations() {
      return this.physicalPerson.secondment_client_relations.length;
    },

    contractorRelations() {
      return this.physicalPerson.secondment_contractor_relations.length;
    },

    partnerRelations() {
      return this.physicalPerson.secondment_partner_relations.length;
    },
    personAssignments() {
      let assignmentList = [];
      if (this.physicalPerson.secondment_client_relations.length > 0) {
        assignmentList.push(this.physicalPerson.secondment_client_relations);
      }
      if (this.physicalPerson.secondment_contractor_relations.length > 0) {
        assignmentList.push(this.physicalPerson.secondment_contractor_relations);
      }
      if (this.physicalPerson.secondment_partner_relations.length > 0) {
        assignmentList.push(this.physicalPerson.secondment_partner_relations);
      }
      return assignmentList;
    }
  },

  async mounted() {
    await this.$store.dispatch("quickActions/setComponent", { component: "BackToWizardBtn" });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Details") },
    ]);
    await this.loadPhysicalPerson();
  },

  async beforeRouteLeave(to, _, next) {
    await this.$store.dispatch("quickActions/setComponent", { component: null });
    if (to.name !== "create-secondment") {
      this.$store.commit("wizard/resetEdit");
      this.$store.commit("wizard/resetDraft");
    }
    next();
  },

  methods: {
    /**
     * Set current active on click.
     * This is a metronic's method.
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    async loadPhysicalPerson() {
      await PhysicalPersonService.getOne(this.userID, this.isApStaff ? true : null).then(res => {
        return PhysicalPersonService.updateDate(res.data)
      }).then(res => {
        this.physicalPerson = res;
      }).catch(err => {
        console.error('@loadPhysicalPerson', err);
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        if (err.response.status === 404) {
          this.$router.push({ name: '404' }); //unauthorized
          return
        }
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },
    async addAsEmployee() {
      this.addEmployeeDisabled = true;
      try {
        await createEmployee(this.physicalPerson.id, this.getCurrentManagedCompanyId, null);
        await this.loadPhysicalPerson();
        successToast(this.$t("Employee added successfully"));
      } catch (err) {
        if (typeof err == "object") {
          backendErrorSwal(this.$t("Failed to add employee"));
          return;
        }
        backendErrorSwal(err);
      } finally {
        this.addEmployeeDisabled = false;
      }
    },
    async rmAsEmployee() {
      this.rmEmployeeDisabled = true;
      try {
        await EmployeeService.dissociateContract(this.physicalPerson.employee_id);
        await this.loadPhysicalPerson();
        successToast(this.$t("Employee remove successfully"));
      } catch (err) {
        if (err.response && err.response.status === 422) {
          if (err.response.data.protected_from === "contract") {
            // Handle the specific error here
            await backendErrorSwal(err, this.$t("Failed to remove employee: This employee has a related contract, terminate or delete the contract first."));
          } else if (err.response.data.protected_from === "secondment") {
            await backendErrorSwal(err, this.$t("Failed to remove employee: This employee has a related secondment, you can terminate the employee contract but you can't delete it."));
          } else {
            // Handle other 422 errors here
            await backendErrorSwal(err, err.response.data.detail);
          }
        } else {
          // Handle other types of errors here
          await backendErrorSwal(err);
        }
      } finally {
        this.rmEmployeeDisabled = false;
      }
    },
    async setAsClientFunction(enable) {
      const res = await physicalPersonSetAsButtonSwal(this.$t("Client"), enable);
      if (res.isConfirmed) {
        this.isSettingAsClient = true;
        this.physicalPerson.secondment_client = enable;
        await PhysicalPersonService.setRoleSecondment(this.physicalPerson.id, { secondment_client: enable }).then(() => {
          this.physicalPerson.is_client_on_secondments = !this.physicalPerson.is_client_on_secondments;
        }).catch(err => {
          this.physicalPerson.secondment_client = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsClient = false;
        });
      }
    },
    async setAsPartnerFunction(enable) {
      const res = await physicalPersonSetAsButtonSwal(this.$t("Supplier"), enable);
      if (res.isConfirmed) {
        this.isSettingAsPartner = true;
        this.physicalPerson.secondment_partner = enable;
        await PhysicalPersonService.setRoleSecondment(this.physicalPerson.id, { secondment_partner: enable }).then(() => {
          this.physicalPerson.is_partner_on_secondments = !this.physicalPerson.is_partner_on_secondments;
        }).catch(err => {
          this.physicalPerson.secondment_partner = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsPartner = false;
        });
      }
    },
    async setAsContractorFunction(enable) {
      const res = await physicalPersonSetAsButtonSwal(this.$t("Contractor"), enable);
      if (res.isConfirmed) {
        this.isSettingAsContractor = true;
        this.physicalPerson.secondment_contractor = enable;
        await PhysicalPersonService.setRoleSecondment(this.physicalPerson.id, { secondment_contractor: enable }).then(() => {
          this.physicalPerson.is_contractor_on_secondments = !this.physicalPerson.is_contractor_on_secondments;
        }).catch(err => {
          this.physicalPerson.secondment_contractor = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsContractor = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.address-card-custom {
  flex-wrap: wrap;
}

@media screen and (min-width: 991.98px) {
  .address-card-custom {
    flex-wrap: nowrap;
  }

  .offcanvas-desktop {
    display: none;
  }
}

.gap-1 {
  gap: 1rem;
}

.info {
  white-space: break-word;
}
</style>
