var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.physicalPerson ? _c('div', {
    staticClass: "d-flex flex-row address-card-custom"
  }, [_c('div', {
    staticClass: "w-100 flex-shrink-0 offcanvas-desktop"
  }, [_c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-around",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c('router-link', {
      staticClass: "navi-link cursor-pointer py-4",
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })])])], 1) : _vm._e()];
  })], 2)]), _c('div', {
    staticClass: "flex-row-auto offcanvas-mobile w-300px w-xl-350px"
  }, [_c('div', {
    staticClass: "card card-custom card-stretch"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('Avatar', {
    staticClass: "mr-3",
    staticStyle: {
      "-webkit-box-shadow": "none",
      "box-shadow": "none"
    },
    attrs: {
      "avatar-image": _vm.physicalPerson.avatar_display,
      "avatar-text": "".concat(_vm.physicalPerson.name[0]).concat(_vm.physicalPerson.surname[0]),
      "frame": "",
      "size": "90px"
    }
  }), _c('div', [_c('a', {
    staticClass: "font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
  }, [_vm._v(_vm._s(_vm.fullName))]), _vm.physicalPerson.is_temporary_employee ? _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Temporary Worker")) + " ")]) : _vm.physicalPerson.is_employee ? _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employee")) + " ")]) : _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Standard person")) + " ")]), _vm.physicalPerson.citizenship_set.length ? _c('div', [_c('country-flag', {
    staticClass: "mt-2",
    attrs: {
      "country-iso": _vm.physicalPerson.citizenship_set[0].country
    }
  })], 1) : _vm._e()])], 1), _c('div', {
    staticClass: "mt-6"
  }, [!_vm.physicalPerson.is_employee ? _c('button', {
    staticClass: "btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4",
    attrs: {
      "disabled": _vm.addEmployeeDisabled
    },
    on: {
      "click": _vm.addAsEmployee
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Group.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add as employee")) + " ")]) : _c('button', {
    staticClass: "btn btn-block btn-sm btn-light-danger font-weight-bolder text-uppercase py-4",
    attrs: {
      "disabled": _vm.rmEmployeeDisabled
    },
    on: {
      "click": _vm.rmAsEmployee
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Group.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Remove as employee")) + " ")])]), _c('div', {
    staticClass: "py-9"
  }, [_vm.isSameAuthUser ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Login mail")) + ": "), _c('span', {
    staticClass: "text-muted",
    style: {
      minWidth: '0'
    }
  }, [_vm._v(_vm._s(_vm.physicalPerson.user_email))])]) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Email")) + ": "), _c('div', {
    staticClass: "text-muted",
    style: {
      minWidth: '0'
    }
  }, [_vm._v(_vm._s(_vm.mainEmail))])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Phone")) + ": "), _c('span', {
    staticClass: "text-muted",
    style: {
      minWidth: '0'
    }
  }, [_vm._v(_vm._s(_vm.mainPhoneNumber))])]), _vm.clientRelations ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Client on")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.clientRelations) + " " + _vm._s(_vm.$t("assignments")))])]) : _vm._e(), _vm.contractorRelations ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Contractor on")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.contractorRelations) + " " + _vm._s(_vm.$t("assignments")))])]) : _vm._e(), _vm.partnerRelations ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Supplier on")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.partnerRelations) + " " + _vm._s(_vm.$t("assignments")))])]) : _vm._e(), !_vm.physicalPerson.is_employee && !_vm.physicalPerson.is_temporary_employee && _vm.physicalPerson.user_id != _vm.getUserID ? _c('div', {
    staticClass: "d-flex flex-wrap gap-1 mt-4"
  }, [_vm.clientRelations == 0 ? _c('div', [_c('client-button', {
    attrs: {
      "loading": _vm.isSettingAsClient,
      "active": _vm.physicalPerson.is_client_on_secondments
    },
    on: {
      "click": function click($event) {
        return _vm.setAsClientFunction(!_vm.physicalPerson.is_client_on_secondments);
      }
    }
  })], 1) : _vm._e(), _vm.contractorRelations == 0 ? _c('div', [_c('contractor-button', {
    attrs: {
      "loading": _vm.isSettingAsContractor,
      "active": _vm.physicalPerson.is_contractor_on_secondments
    },
    on: {
      "click": function click($event) {
        return _vm.setAsContractorFunction(!_vm.physicalPerson.is_contractor_on_secondments);
      }
    }
  })], 1) : _vm._e(), _vm.partnerRelations == 0 ? _c('div', [_c('supplier-button', {
    attrs: {
      "loading": _vm.isSettingAsPartner,
      "active": _vm.physicalPerson.is_partner_on_secondments
    },
    on: {
      "click": function click($event) {
        return _vm.setAsPartnerFunction(!_vm.physicalPerson.is_partner_on_secondments);
      }
    }
  })], 1) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c('router-link', {
      staticClass: "navi-link cursor-pointer py-4",
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })]), _c('span', {
      staticClass: "navi-text font-size-lg"
    }, [_vm._v(_vm._s(comp.name))])])], 1) : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "flex-row-fluid ml-lg-8"
  }, [_c('router-view', _vm._g(_vm._b({
    key: _vm.$route.fullPath
  }, 'router-view', _vm.currentDetailComponentProps, false), _vm.currentDetailComponentEvents))], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }